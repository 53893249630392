.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Ensure relative positioning for absolute children */
}

.Upper-logo,
.Lower-logo {
  height: 50vmin; /* Adjust size as needed */
  align-self: center; /* Center vertically within logos-container */
}

.Upper-logo {
  position: relative; /* Ensure the logo is positioned relative to its container */
}

.Lower-logo {
  position: absolute; /* Position the lower logo absolutely */
  top: 0; /* Position at the top of logos-container */
}



@keyframes breathe {
  0%, 100% {
    transform: scale(1)  translateY(0) rotate(0); /* Start and end with normal size */
    opacity: 1;
  }
  50% {
    transform: scale(1.15) translateY(-5px) rotate(20deg);
        opacity: 0.8;
  }

}

@media (prefers-reduced-motion: no-preference) {
  .Upper-logo {
    animation: breathe infinite 6s ease-in-out; /* Adjust animation duration and timing function as needed */
  }
}

@media (prefers-reduced-motion: no-preference) {

  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  
}

.App-header {
  background-image: url('./bg1.jpg');
  background-size: cover; /* Ensures the image covers the entire background */
  background-position: center; /* Centers the background image */
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  gap: 80px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
